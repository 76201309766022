

import React from "react";
import styles from './Search.module.scss'


export enum SearchSize {
  Medium = 'normal (default)',
  Large = 'large'
}


type SearchProps = {

  defaultValue?: string,
  value?: string,
  placeholder?: string,
  onChange?: (input: string) => void,
  size?: SearchSize,
  className?: string,
}




export const Search = (
  {
    placeholder = "Zoeken op naam...",
    onChange,
    size,
    className,
    ...props
  }: SearchProps) => {


  // return <form className={`${className && className} ${styles.search} ${size === SearchSize.Large && styles.large}`} onSubmit={handleSubmit}>
  //     <input type="text" value={value} onChange={(e) => setValue(e.target.value)} placeholder={placeholder} tabIndex={1}/>
  //     <button type="submit">
  //       <i className="fa-solid fa-magnifying-glass"></i>
  //     </button>
  //   </form>
  const isLarge = size && size === SearchSize.Large.valueOf()

  return <div className={`${className && className} ${styles.search} ${isLarge && styles.large}`}>
    <input type="text" {...props} placeholder={placeholder} tabIndex={1} onChange={(e) => onChange && onChange(e.target.value)}/>
    <button type="submit">
      <i className="fa-solid fa-magnifying-glass"></i>
    </button>
  </div>
};




export default Search
